const baseURL = import.meta.env.VITE_APP_API_BASE_URL

const API = {
  API: {
    TOKEN: {
      INDEX: `${baseURL}api/token/`,
      REFRESH: `${baseURL}api/token/refresh/`
    }
  },
  ROLES: {
    INDEX: `${baseURL}roles/`
  },
  SECTION_GEO_DATA: {
    GET_FIELD_NAMES: `${baseURL}section_geo_data/get_field_names/`,
    SECTION_LINE_UPLOAD_COLLECTIONS: {
      INDEX: `${baseURL}section_geo_data/section_line_upload_collections/`,
      UPLOAD_TO_PROJECT: (projectId) =>
        `${baseURL}section_geo_data/section_line_upload_collections/upload_to_project/${projectId}/`
    },
    SECTION_LINES: {
      INDEX: `${baseURL}section_geo_data/section_lines/`,
      UPLOAD_POLYGON_SHAPEFILE: `${baseURL}section_geo_data/section_lines/upload_polygon_shapefile/`
    },
    SECTION_LINES_GI_POINTS: {
      INDEX: `${baseURL}section_geo_data/section_lines_gi_points/`
    },
    SELECTION_POLYGONS: {
      INDEX: `${baseURL}section_geo_data/section_selection_polygons/`
    },
    RASTER_FILE_UPLOAD: {
      INDEX: `${baseURL}section_geo_data/raster_file_uploads/`
    }
  },
  GI_DATA: {
    GI_DATA_UPLOAD_COLLECTIONS: {
      INDEX: `${baseURL}gi_data/gi_data_upload_collections/`
    },
    GI_POINTS: {
      INDEX: `${baseURL}gi_data/gi_points/`
    }
  },
  SPECKLE: {
    BASE: `${baseURL}speckle/`,
    SEND: `${baseURL}speckle/send/`
  },
  CORE: {
    PROJECTS: {
      INDEX: `${baseURL}core/projects/`,
      GET_UPLOAD_URI: `${baseURL}core/projects/get_upload_uri/`
    },
    PROJECT_USERS: {
      INDEX: `${baseURL}core/project_users/`
    },
    USERS: {
      INDEX: `${baseURL}core/users/`,
      CURRENT_USER: `${baseURL}core/users/current_user`
    }
  }
}

export default API
